import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { logIn, logOut } from "../../../stores/NetworkStore";
import { FIREBASE_CONFIG } from "../../utils/utils";
import "firebaseui/dist/firebaseui.css";
import "./login.css";

export default function Login() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uid = useAppSelector((state) => state.network.uid);
  const displayName = useAppSelector((state) => state.network.displayName);

  const [isRegistering, setIsRegistering] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayNameInput, setDisplayNameInput] = useState("");
  const [error, setError] = useState<string | null>(null);

  // 初始化 Firebase
  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(FIREBASE_CONFIG);
    }
  }, []);

  // 监听用户登录状态
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch(logIn({
          uid: user.uid,
          displayName: user.displayName || user.email || '',
          email: user.email || ''
        }));
      } else {
        dispatch(logOut());
      }
    });

    // 清理订阅
    return () => unsubscribe();
  }, [dispatch]);

  // 注册逻辑
  const registerUser = async () => {
    setError(null);
    try {
      const userCredential = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      
      // 更新显示名称
      if (userCredential.user) {
        await userCredential.user.updateProfile({ 
          displayName: displayNameInput || email.split('@')[0] 
        });
      }
      
      navigate("/lobby");
    } catch (error: any) {
      setError(error.message);
      console.error(error);
    }
  };

  // 登录逻辑
  const loginUser = async () => {
    setError(null);
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      navigate("/lobby");
    } catch (error: any) {
      setError(error.message);
      console.error(error);
    }
  };

  // 退出登录逻辑
  const logoutUser = async () => {
    try {
      await firebase.auth().signOut();
    } catch (error) {
      console.error(error);
    }
  };

  if (!uid) {
    // 未登录时渲染登录/注册界面
    return (
      <div id="auth-panel">
        <h1>{isRegistering ? "注册" : "登录"}</h1>
        
        {error && <div style={{ color: 'red', marginBottom: '10px', textAlign: 'center' }}>{error}</div>}
        
        <input
          type="email"
          placeholder="请输入邮箱"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
         placeholder="请输入密码"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          minLength={6}
        />
        {isRegistering && (
          <input
            type="text"
            placeholder="请输入用户名"
            value={displayNameInput}
            onChange={(e) => setDisplayNameInput(e.target.value)}
          />
        )}
        <button
          className="auth-button"
          onClick={isRegistering ? registerUser : loginUser}
        >
          {isRegistering ? "注册" : "登录"}
        </button>
        <button
          className="switch-button"
          onClick={() => {
            setIsRegistering(!isRegistering);
            setError(null);
          }}
        >
          {isRegistering ? "登录" : "注册"}
        </button>
      </div>
    );
  } else {
    // 已登录时渲染用户信息和操作按钮
    return (
      <div id="play-panel">
        <p>
          {t("authenticated_as")}:{" "}
          <span title={displayName}>{displayName || t("anonymous")}</span>
        </p>
        <ul className="actions">
          <li>
            <button className="bubbly green" onClick={() => navigate("/lobby")}>
              {t("join_lobby")}
            </button>
          </li>
          <li>
            <button className="bubbly red" onClick={logoutUser}>
              {t("sign_out")}
            </button>
          </li>
        </ul>
      </div>
    );
  }
}